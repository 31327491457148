import DescriptionCard from "./DescriptionCard"

type Props = {
  title: string
  body: string
}

const MeritDescriptionCard = (props: Props) => {
  const { title, body } = props
  return <DescriptionCard title={title} body={body} color="red" />
}

export default MeritDescriptionCard
