import { Box, Flex, Text } from "@chakra-ui/react"

type Props = {
  title: string
  body: string
  color: string
}

const DescriptionCard = (props: Props) => {
  const { title, body, color } = props
  const border = `1px solid ${color}`
  return (
    <Flex direction="column" border={border} p={2} h={36} w={72}>
      <Box>
        <Text fontSize={18} textAlign="center" fontWeight="bold">
          {title}
        </Text>
      </Box>
      <Flex h="100%" justifyContent="center" alignItems="center">
        <Text fontSize={14} dangerouslySetInnerHTML={{ __html: body }} />
      </Flex>
    </Flex>
  )
}

export default DescriptionCard
