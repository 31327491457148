import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Grid,
  theme,
  Flex,
  GridItem,
  Button,
  Img,
  Center,
} from "@chakra-ui/react"
import FunctionDescriptionCard from "./components/FunctionDescriptionCard"
import FormModal from "./components/FormModal"
import { useState } from "react"
import MeritDescriptionCard from "./components/MeritDescriptionCard"

export const App = () => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" pl={[0, 36]} pr={[0, 36]}>
        <Grid minH="100vh" p={4}>
          <VStack spacing={4}>
            <Text color="#1E90FF" fontSize="24" fontWeight="bold">
              タブレットだけで始められる
              <br />
              モバイルオーダー
            </Text>
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}>
              <GridItem>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  h={["260px", "480px"]}
                >
                  <Img src="/images/first_view.png" />
                </Flex>
              </GridItem>
              <GridItem>
                <Center>
                  <Img mt={12} h="420px" src="/images/first_view_iphone.png" />
                </Center>
              </GridItem>
            </Grid>
            <Text color="#1E90FF" fontSize="24" fontWeight="bold">
              TSUTAETE（ツタエテ）
            </Text>
            <Grid
              templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
              gap={6}
            >
              <GridItem>
                <FunctionDescriptionCard
                  title="QRコードでオーダー"
                  body="お客さんがスマホから注文できます。<br>スタッフにはリアルタイムでオーダーが通知されます。"
                />
              </GridItem>
              <GridItem>
                <FunctionDescriptionCard
                  title="プライベートレビュー"
                  body="お客様の声を聞けます。<br>公開されないため、<br>本音で伝えてもらえます。"
                />
              </GridItem>
              <GridItem>
                <FunctionDescriptionCard
                  title="レシート印刷"
                  body="紙のレシートが欲しいお客様のために、<br>レシートを印刷することが可能です。<br><span style='color: red; font-size: 8px'>※別途、印刷用の端末をご購入頂く必要がございます</span>"
                />
              </GridItem>
              <GridItem>
                <FunctionDescriptionCard
                  title="売上分析"
                  body="売上のランキングや推移をグラフで視覚的に見れます。<br>戦略の立案に役立ちます。"
                />
              </GridItem>
              <GridItem>
                <FunctionDescriptionCard
                  title="多言語対応"
                  body="メニュー名を自動で翻訳。<br>外国人旅行客様にもカンタンに説明できます。"
                />
              </GridItem>
              <GridItem>
                <FunctionDescriptionCard
                  title="お問い合わせ"
                  body="お客様からのお問い合わせをチャットでやり取りできます。<br>タブレットのほか、パソコンからも使えるので本部から返信するといった事も可能。"
                />
              </GridItem>
            </Grid>
            <Box h={6} />
            <Flex
              direction="column"
              bgColor="#F5F5FF"
              pl={24}
              pr={24}
              pt={12}
              pb={12}
            >
              <Box mb={8} fontWeight="bold" fontSize={24}>
                お申し込みまでの流れ
              </Box>
              <Box textAlign="left">
                <Text
                  as="span"
                  fontSize={16}
                  color="red"
                  textDecoration="underline"
                >
                  STEP 1.
                </Text>
                <Box ml={4}>
                  <Text as="span">フォームからお申し込みください</Text>
                </Box>
              </Box>
              <Text textAlign="left" color="blue" mt={2} mb={2}>
                ↓
              </Text>
              <Box textAlign="left">
                <Text
                  as="span"
                  fontSize={16}
                  color="red"
                  textDecoration="underline"
                >
                  STEP 2.
                </Text>
                <Box ml={4}>
                  <Text as="span">
                    2営業日以内にご案内メールをお送りさせていただきます
                  </Text>
                </Box>
              </Box>
              <Text textAlign="left" color="blue" mt={2} mb={2}>
                ↓
              </Text>
              <Box textAlign="left">
                <Text
                  as="span"
                  fontSize={16}
                  color="red"
                  textDecoration="underline"
                >
                  STEP 3.
                </Text>
                <Box ml={4}>
                  <Text as="span">
                    日程を調整の上、貴店へお伺いしシステムの説明をいたします
                  </Text>
                </Box>
              </Box>
              <Text textAlign="left" color="blue" mt={2} mb={2}>
                ↓
              </Text>
              <Box textAlign="left">
                <Text
                  as="span"
                  fontSize={16}
                  color="red"
                  textDecoration="underline"
                >
                  STEP 4.
                </Text>
                <Box ml={4}>
                  <Text as="span">
                    契約が成立しましたら、貴店のアカウントの発行をいたします
                  </Text>
                </Box>
              </Box>
            </Flex>
            <Flex pt={4}>
              <Button colorScheme="blue" onClick={() => setOpenModal(true)}>
                お申し込みする
              </Button>
            </Flex>
            <Box h={4} />
            <Grid
              templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
              gap={6}
            >
              <GridItem>
                <MeritDescriptionCard
                  title="始めるのは簡単"
                  body="周辺機器の用意は必要ありません。<br>タブレットやパソコンだけあれば操作ができます"
                />
              </GridItem>
              <GridItem>
                <MeritDescriptionCard
                  title="まだベータ版です"
                  body="貴店の声を聞かせたください！<br>ご要望があれば機能追加として検討致します"
                />
              </GridItem>
              <GridItem>
                <MeritDescriptionCard
                  title="お問い合わせ機能だけでもお使い頂けます"
                  body="飲食店の他、お祭り会場やイベントのお問い合わせ先としてQRコードを置けば<br>チャットで対話ができます"
                />
              </GridItem>
            </Grid>
            <Box h={24} />
          </VStack>
          <FormModal isOpen={openModal} onClose={() => setOpenModal(false)} />
        </Grid>
      </Box>
    </ChakraProvider>
  )
}
