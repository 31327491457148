import {
  Text,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import axios, { AxiosRequestConfig } from "axios";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const FormModal = (props: Props): React.ReactElement => {
  const { isOpen, onClose } = props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [body, setBody] = useState("");

  const onSubmit = () => {
    const params = {
      name,
      email,
      organizationName,
      body,
    };

    const axiosInstance = axios.create({
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        timeout: 60000,
      },
    });

    axiosInstance
      .post(
        "https://cswh7bklg2.execute-api.ap-northeast-1.amazonaws.com/default",
        params
      )
      .then((res: any) => {
        alert("申し込みが完了しました！");
        onClose();
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>申し込みフォーム</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <Flex direction="column" gap={8}>
            <Flex alignItems="center">
              <Text flexBasis="36">氏名</Text>
              <Input
                w={80}
                placeholder="山田 太郎"
                onChange={(e) => setName(e.target.value)}
              />
            </Flex>
            <Flex alignItems="center">
              <Text flexBasis="36">メールアドレス</Text>
              <Input
                w={80}
                placeholder="xxx@example.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Flex>
            <Flex alignItems="center">
              <Text flexBasis="36">店舗名</Text>
              <Input
                w={80}
                placeholder="xxxレストラン"
                onChange={(e) => setOrganizationName(e.target.value)}
              />
            </Flex>
            <Flex alignItems="center">
              <Text flexBasis="36">本文</Text>
              <Textarea
                placeholder=""
                onChange={(e) => setBody(e.target.value)}
              />
            </Flex>
          </Flex>
          <Flex justifyContent="center">
            <Button mt={12} colorScheme="blue" onClick={() => onSubmit()}>
              送信
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FormModal;
